// Built-ins
import React from "react";

// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// CSS
import "react-tabs/style/react-tabs.css";

// Custom Made Components
import NavigationBar from "../../core/navigation_bar/navigation_bar";
import Footer from "../../core/footer/footer";
import CharterBrokerOverView from "./charter_broker_overview/charter_broker_overview";

class CharterBroker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charter_broker: {},
    };
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Tabs>
          <TabList>
            <Tab> Overview </Tab>
          </TabList>
          <TabPanel>
            <CharterBrokerOverView />
          </TabPanel>
        </Tabs>
        <Footer />
      </div>
    );
  }
}

export default CharterBroker;
